import React from "react"
import {graphql} from "gatsby";
import {Container} from 'reactstrap'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import BloqueImagenTexto from "../components/bloqueImagenTexto";
import {LangContext} from "../components/lurraldebusWrapper";


const AccesibilidadPage = ({data}) => {
  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'accesibilidad', 'titulo')}/>
          <Container className="py-5  mb-5">
            <PageTitle title={context.translate(data, 'accesibilidad', 'titulo')} />
            {context.translate(data, 'accesibilidad', 'secciones').map(item =>
              <BloqueImagenTexto
                key={item.id}
                banner={item.banner}
                title={item.encabezado}
                children={item.texto}
              />
            )}
          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default AccesibilidadPage

export const pageQuery = graphql`
  query {
    accesibilidad: strapiAccesibilidad {
      titulo
      secciones {
        id
        encabezado
        texto
        banner {
          publicURL
        }
      }
    }
    accesibilidad_eus: strapiAccesibilidadEus {
      titulo
      secciones {
        id
        encabezado
        texto
        banner {
          publicURL
        }
      }
    }
  }
`
